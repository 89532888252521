import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [13],
		"/admin": [14,[2]],
		"/admin/adminusers": [15,[2]],
		"/admin/features": [16,[2]],
		"/admin/logs": [17,[2]],
		"/admin/organisations": [18,[2]],
		"/admin/servers": [19,[2]],
		"/admin/users": [20,[2]],
		"/api": [21],
		"/api/auth/events/login": [~22],
		"/app": [23,[3]],
		"/app/[organisation_id]": [24,[3,4]],
		"/app/[organisation_id]/daily": [25,[3,4]],
		"/app/[organisation_id]/monthly": [26,[3,4,5]],
		"/app/[organisation_id]/rundown/[rundown_id]": [27,[3,4,6]],
		"/app/[organisation_id]/settings": [28,[3,4,7]],
		"/app/[organisation_id]/settings/_contentblocks": [29,[3,4,7]],
		"/app/[organisation_id]/settings/_mam": [30,[3,4,7]],
		"/app/[organisation_id]/settings/_mam/[mam_id]": [31,[3,4,7]],
		"/app/[organisation_id]/settings/_mappings": [32,[3,4,7,8]],
		"/app/[organisation_id]/settings/ai": [33,[3,4,7,9]],
		"/app/[organisation_id]/settings/ai/[model_id]": [34,[3,4,7,9]],
		"/app/[organisation_id]/settings/dossiers": [35,[3,4,7]],
		"/app/[organisation_id]/settings/dossiers/[dossier_id]": [36,[3,4,7]],
		"/app/[organisation_id]/settings/feeds": [37,[3,4,7]],
		"/app/[organisation_id]/settings/feeds/[feed_id]": [38,[3,4,7]],
		"/app/[organisation_id]/settings/inbound": [39,[3,4,7]],
		"/app/[organisation_id]/settings/inbound/[inbound_id]": [40,[3,4,7]],
		"/app/[organisation_id]/settings/lists": [41,[3,4,7]],
		"/app/[organisation_id]/settings/lists/[list_id]": [42,[3,4,7]],
		"/app/[organisation_id]/settings/members": [43,[3,4,7,10]],
		"/app/[organisation_id]/settings/publishpoints": [44,[3,4,7]],
		"/app/[organisation_id]/settings/publishpoints/[publishpoint_id]": [45,[3,4,7]],
		"/app/[organisation_id]/settings/storyproperties": [46,[3,4,7]],
		"/app/[organisation_id]/table": [47,[3,4,11]],
		"/app/[organisation_id]/weekly": [48,[3,4,12]],
		"/test": [49],
		"/test/cuez/config": [50],
		"/test/hotreloading": [51],
		"/test/tiptap": [52],
		"/test/upload": [53]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';